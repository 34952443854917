<template>
  <main-container>
    <div class="text-h1 mt-12" v-if="$store.state.dataLoaded">
      Page Not Found
    </div>
  </main-container>
</template>

<script>
import MainContainer from "@/components/MainContainer";
export default {
  components: { MainContainer }
};
</script>
